import React, { ReactNode, ReactElement } from 'react'
import styles from './Main.layout.module.scss'

import Image from 'next/image'
import { useRouter } from 'next/router'

import { SideBar, Button, Header, Title } from '@/shared'
import { useGlobalContext } from '@/contexts/AppContext'
import Search from '@/views/Info/components/InfoSearch'

interface Props {
  children: ReactNode
  page?: string
  title?: string
  description?: string
}

const Main = ({ children, page, title, description }: Props): ReactElement => {
  const { collapseSidebar }: any = useGlobalContext()
  const router = useRouter()
  return (
    <div className={styles.layout} id="main-layout" data-collapsed={collapseSidebar}>
      <aside>
        <SideBar transparent={true} />
      </aside>
      <div className={styles.layout_content}>
        <Header />
        <main className={styles.layout_main}>
          <div className={styles.layout_main_wrapper}>
            {['404', 'pool', 'farm'].includes(page) && (
              <div className={styles.layout_breadcrumbs}>
                <Button
                  className={styles.layout_breadcrumbsBtn}
                  onClick={() => (page !== '404' ? router.back() : router.push('/'))}
                  type="transparent"
                >
                  <div className={styles.layout_breadcrumbsBtn_icon}>
                    <Image src="/svgs/arrow-left.svg" layout="fill" alt="" />
                  </div>
                  <span className={styles.layout_breadcrumbsText}>{`${
                    page !== '404' ? 'Go Back' : 'Back to home'
                  }`}</span>
                </Button>
              </div>
            )}
            <div className={styles.layout_mobSearch}>
              <Search />
            </div>
            {title && <Title title={title} description={description} />}
            {children}
          </div>
        </main>
      </div>
    </div>
  )
}

export default Main
